import axios from 'axios';
import qs from 'qs';
// import { CORPID, USERID, DEBUG } from "@/utils/config.js";
// const BASEURL = import.meta.env.VITE_APP_PY_URL; // 配置接口地址

// axios.defaults.baseURL = BASEURL;
axios.defaults.timeout = 50000; // 响应时间
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded; charset=UTF-8'; // 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'; // 配置请求头

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做某件事
    if (config.method === 'post') {
      // 修改请求前的操作
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  error => {
    console.log('错误的传参');
    return Promise.reject(error);
  }
);

// 返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  res => {
    // 对响应数据做些事
    if (!res.data.success) {
      return Promise.resolve(res);
    }
    return res;
  },
  error => {
    console.log('网络异常');
    return Promise.reject(error);
  }
);

// 成功处理
function IsSuccess(resolve, res) {
  resolve(res);
}
// 错误处理
function IsFail(reject, res) {
  console.error(res.errmsg);
  reject(res);
}

// 开始创建请求实例
const Http = options => {
  // 默认参数 可配可传
  // if (!options.noDef) {
  // }

  return new Promise((resolve, reject) => {
    // 根据请求方式，更改参数名 get-》params，post-》data
    let api = {
      method: options.method,
      url: options.url,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    };
    if (options.method === 'GET') {
      api.params = options.params;
    } else {
      api.data = options.params;
    }

    axios(api)
      .then(res => {
        // 根据返回的状态码判断，注意res返回的并不一定都是status，比如小程序就是statusCode

        if (res.status == 200) {
          // 请求成功
          // console.log('请求成功');
          IsSuccess(resolve, res.data);
          // if (res.data.errcode == 0) {

          // } else {
          //   IsFail(reject, res.data);
          // }
        } else {
          // 请求失败
          console.error('接口错误', api_.url);
        }
      })
      .catch(err => {
        IsFail(reject, err);
      });
  });
};

export default Http;
